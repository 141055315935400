<template>
  <div class="invoice-container">
    <div class="invoice_content">
      <div class="invoice_search">
        <a-button type="primary" @click="handleClickAdd(1)" class="button">申请发票</a-button>
        <div>
          <span class="invoice_date" style="margin-left: 10px">选择日期</span>
          <a-range-picker
            style="margin-left: 10px; width: 215px"
            @change="onChange"
            v-model="dateString"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :disabledDate="disabledDate"
            :allowClear="true"
            show-time
            format="YYYY-MM-DD"
          >
            <a-icon slot="suffixIcon" type="smile" />
          </a-range-picker>
          <a-select v-model="invoiceSts" allowClear placeholder="发票状态" style="width: 200px; margin-left: 10px">
            <a-select-option value="0"> 待开具 </a-select-option>
            <a-select-option value="1"> 已经开具 </a-select-option>
          </a-select>
          <a-select
            allowClear
            class="search_item"
            v-model="partyType"
            placeholder="请选择账户类型"
            style="width: 200px; margin-left: 10px"
          >
            <a-select-option value="1"> 广告主 </a-select-option>
            <a-select-option value="2"> 代理商 </a-select-option>
            <a-select-option value="3"> 广告平台 </a-select-option>
            <a-select-option value="4"> 平台媒体 </a-select-option>
          </a-select>
          <a-input
            allowClear
            class="search_item"
            v-model.trim="partyName"
            autocomplete="off"
            placeholder="请输入公司名称"
            style="width: 200px; margin-left: 10px"
          />
          <a-button style="margin-left: 10px" type="primary" @click="handleSearch" class="button">查询</a-button>
        </div>
      </div>
      <div class="invoice_table">
        <a-spin :spinning="loading">
          <a-table
            class="m_table_cust"
            :pagination="false"
            :columns="columns"
            :data-source="dataList"
            :rowKey="
              (record, index) => {
                return index
              }
            "
            bordered
          >
            <div slot="accounts">直客</div>
            <template slot="action" slot-scope="text, record">
              <a-button size="small" @click="handleClickUpdatePlace(record)" class="button">修改</a-button>
            </template>
          </a-table>
          <a-pagination
            class="pagination"
            v-if="dataList.length > 0"
            show-size-changer
            :current="query.page"
            :show-total="(total) => `共 ${total} 条`"
            :total="total"
            :pageSize="query.limit"
            @change="handleChangePage"
            @showSizeChange="onShowSizeChange"
          />
          <m-empty v-else style="min-height: 80vh">
            <template #description>暂无数据</template>
          </m-empty>
        </a-spin>
      </div>
    </div>
    <NewInvoice
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="this.advertisers"
      @changePlace="changePlace"
    />
    <Views
      v-if="addPlaceView"
      :placeView="placeView"
      :visible="addPlaceView"
      :isAdd="this.adverView"
      @modalCancel="addPlaceView = false"
      @changePlace="changePlace"
    />
    <!-- 批量操作删除提示弹窗 -->
    <a-modal v-model="popconfirmVisible" title="提示" @cancel="handleCancel" :confirm-loading="confirmLoading">
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>您账户的开票资质信息还未完善，请先完善开票资质信息！</p>
      </div>
      <template slot="footer">
        <a-button @click="handleCancel">关闭</a-button>
        <a-button type="primary" @click="batchOperationSts">去完善</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { invoiceListPage } from '@/api/financialManagementA'
import { taxInfoList } from '@/api/media'
import NewInvoice from '../../components/newInvoice'
import Views from '../../components/view'
import mixDate from '@/mixins/initDate'
import { columns } from './list'
import { getDuration } from '@/utils/duration'
export default {
  name: 'Contract',
  mixins: [mixDate],
  components: { NewInvoice, Views },
  data () {
    return {
      contractType: undefined,
      invoiceSts: undefined,
      partyType: undefined,
      partyName: undefined,
      advertisers: '',
      adverView: '',
      columns,
      addPlaceVisible: false,
      addPlaceView: false,
      placeInfo: {},
      placeView: {},
      loading: false,
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      dateStrings: [],
      dateString: [],
      dataList: [],
      times: [],
      keys: '',
      popconfirmVisible: false,
      confirmLoading: false,
      check: false // 是否有开票资质
    }
  },
  mounted () {
    this.getInvoiceListPage()
    // 获取资质发票信息
    this.getTaxInfoList()
  },
  methods: {
    // 完善资质取消按钮
    handleCancel () {
      this.popconfirmVisible = false
    },
    // 获取资质发票信息
    async getTaxInfoList () {
      const res = await taxInfoList()
      if (res.code === 200 && res.data) {
        const {
          accountName = '',
          taxNumber = '',
          registerAddress = '',
          taxPhone = '',
          accountBank = '',
          accountCode = '',
          invoiceType = '',
          check = false
        } = res.data[0] || {}
        this.placeInfo = {
          registerAddress,
          accountName,
          taxNumber,
          taxPhone,
          accountBank,
          accountCode,
          invoiceType
        }
        this.check = check
      }
    },
    // 去完善
    batchOperationSts () {
      this.popconfirmVisible = false
      this.$router.push({
        path: `/accountInfo/invoice?target=qualification`
      })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 时间选择
    onChange (date, dateString) {
      this.dateStrings = dateString
      this.times = this.dateStrings
    },
    async getInvoiceListPage () {
      this.loading = true
      this.dataList = []
      const res = await invoiceListPage(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    // 创建发票
    handleClickAdd (type) {
      // 有发票资质信息
      if (this.check) {
        this.advertisers = 1
        this.addPlaceVisible = true
      } else {
        // 否则、校验弹窗去完善
        this.popconfirmVisible = true
      }
    },
    // 搜索
    handleSearch () {
      this.query = {
        page: 1,
        limit: 10,
        startTime: this.times[0] || '',
        endTime: this.times[1] || '',
        partyType: this.partyType,
        partyName: this.partyName,
        invoiceSts: this.invoiceSts
      }
      this.getInvoiceListPage()
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.advertisers = 2
      this.placeInfo = record
      this.addPlaceVisible = true
    },
    changePlace () {
      if (this.keys === '1' || this.keys === '') {
        this.getInvoiceListPage()
      } else if (this.keys === '2') {
        this.getAgentListPage()
      }
    },
    // 查看
    handleClickView (record) {
      this.placeView = record
      this.addPlaceView = true
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getInvoiceListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getInvoiceListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal {
    top: 22%;
    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }
    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }
}
.invoice-container {
  padding: 10px;
  .invoice_content {
    padding-top: 10px;
    .invoice_search {
      padding: 0 20px;
      border-radius: 5px;
      background: #fff;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
    .invoice_table {
      min-height: 80vh;
      height: 50px;
      padding: 10px 20px;
      height: 100%;
      background: #fff;
      border-radius: 5px;
    }
    .pagination {
      margin-top: 20px;
    }
  }
}
</style>
