import { numFormat } from '@/utils/dealNumber'
export const columns = [
  {
    dataIndex: 'addTime',
    title: '日期',
    key: 'addTime',
    align: 'left'
  },
  {
    dataIndex: 'invoiceSts',
    title: '发票状态',
    key: 'invoiceSts',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.invoiceSts === '0') {
        text = '待开具'
      } else if (record.invoiceSts === '1') {
        text = '已经开具'
      }
      return text
    }
  },
  {
    dataIndex: 'companyName',
    title: '公司',
    key: 'companyName',
    align: 'left',
    customRender: (companyName, record, index) => {
      return companyName || '-'
    }
  },
  {
    dataIndex: 'invoiceMoney',
    title: '金额',
    key: 'invoiceMoney',
    align: 'left',
    customRender: (text, record, index) => {
      text = record.invoiceMoney || record.invoiceMoney === 0 ? numFormat(record.invoiceMoney, 3, '') : '-'
      return text
    }
  },
  {
    dataIndex: 'invoiceType',
    title: '发票类型',
    key: 'invoiceType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.invoiceType === '1') {
        text = '增值税专用发票'
      } else if (record.invoiceType === '2') {
        text = '增值税普通发票'
      }
      return text
    }
  },
  {
    dataIndex: 'partyType',
    title: '账户类型',
    key: 'partyType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.partyType === '1') {
        text = '广告主'
      } else if (record.partyType === '2') {
        text = '代理商'
      } else if (record.partyType === '3') {
        text = '广告平台'
      } else if (record.partyType === '4') {
        text = '平台媒体'
      }
      return text || '-'
    }
  },
  // {
  //   dataIndex: 'incomeType',
  //   title: '收入模式',
  //   key: 'incomeType',
  //   align: 'left',
  //   customRender: (text, record, index) => {
  //     if (record.incomeType === '1') {
  //       text = '直客收入'
  //     } else if (record.incomeType === '2') {
  //       text = '平台收入'
  //     }
  //     return text
  //   }
  // },
  // {
  //   dataIndex: 'settleModel ',
  //   title: '结算模式',
  //   key: 'settleModel ',
  //   align: 'left',
  //   customRender: (text, record, index) => {
  //     if (record.settleModel === '1') {
  //       text = '预收货款'
  //     } else if (record.settleModel === '2') {
  //       text = '平台结算'
  //     }
  //     return text
  //   }
  // },
  {
    dataIndex: 'remark',
    title: '备注',
    key: 'remark',
    align: 'left',
    customRender: (remark, record, index) => {
      return record.remark || '-'
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
